import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        };
        handleScroll();
    }, [pathname]);

    return null; // Ensure the component does not render any DOM elements
};

export default ScrollToTop;
