import { useState, useEffect, useRef } from "react";
import logo from "../Assets/images/logo mini.png";
import { TiHomeOutline } from "react-icons/ti";
import { BiCategoryAlt } from "react-icons/bi";
import { TiGroupOutline } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import CartNavigation from "../Cart Navigation/CartNavigation";
import SearchWeb from "../Search/SearchWeb";
import SearchMobile from "../Search/SearchMobile";

const Navbar = () => {
  const [selectedMenu, setSelectedMenu] = useState("");
  const [toggleNav, setToggleNav] = useState(false);
  const location = useLocation();
  const navRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setToggleNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setSelectedMenu("home");
        break;
      case "/categories":
        setSelectedMenu("categories");
        break;
      case "/authors":
        setSelectedMenu("authors");
        break;
      default:
        setSelectedMenu("");
    }
  }, [location.pathname]);

  const vars = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    whileInView: { opacity: 1, y: 0 },
  };

  const menuVars = {
    initial: { opacity: 1, width: 0 },
    animate: {
      opacity: 1,
      width: "18rem",
      transition: {
        duration: 0.7,
      },
    },
    exit: {
      opacity: 1,
      width: 0,
      transition: {
        duration: 0.7,
        delay: 0.2,
      },
    },
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="w-screen flex items-center justify-center sticky top-0 z-50 border-b-2 font-tajawal">
      {/* webview */}
      <div className="w-full  flex items-center justify-between px-8 py-3 z-50 bg-white">
        {/* left-side */}
        <div className="flex items-center justify-center space-x-2">
          <div className="flex items-center justify-center w-full h-full">
            <CartNavigation />
          </div>
          <div className="md:hidden flex items-center justify-center">
            <Link to="/" onClick={handleLinkClick}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{
                  type: "spring",
                  damping: 17,
                  stiffness: 400,
                }}
                className="w-11 h-11 rounded-lg border-2 border-black bg-yellow-400 flex items-center 
                justify-center"
              >
                <img src={logo} alt="logo" className=" w-full h-full" />
              </motion.div>
            </Link>
          </div>
        </div>
        {/* right-side */}
        <div
          dir="rtl"
          className="hidden md:flex items-center justify-center gap-2 overflow-visible"
        >
          <Link to="/" onClick={handleLinkClick}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{
                type: "spring",
                damping: 17,
                stiffness: 400,
              }}
              className="w-11 h-11 rounded-lg border-2 border-black bg-yellow-400 flex items-center 
              justify-center"
            >
              <img src={logo} alt="logo" className="object-cover" />
            </motion.div>
          </Link>
          <motion.div
            variants={vars}
            initial="initial"
            whileInView="whileInView"
            transition={{
              duration: 0.3,
            }}
            onClick={handleLinkClick}
          >
            <Link
              onClick={() => setSelectedMenu("home")}
              to="/"
              className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
              ease-in-out duration-200  ${selectedMenu === "home"
                  ? "-translate-y-2 py-4"
                  : "hover:-translate-y-2 hover:py-4"
                }`}
            >
              <p dir="rtl" className="font-tajawal">
                الرئيسيه
              </p>
              <TiHomeOutline />
              <span
                className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
              transition-all duration-200 flex items-center justify-center ${selectedMenu === "home" ? "w-full" : "group-hover:w-full w-0 "
                  }`}
              ></span>
            </Link>
          </motion.div>
          <motion.div
            variants={vars}
            initial="initial"
            whileInView="whileInView"
            transition={{
              duration: 0.3,
              delay: 0.1,
            }}
          >
            <Link
              to="/categories"
              onClick={handleLinkClick}
              className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
              ease-in-out duration-200  ${selectedMenu === "categories"
                  ? "-translate-y-2 py-4"
                  : "hover:-translate-y-2 hover:py-4"
                }`}
            >
              <p dir="rtl" className="font-tajawal">
                اقسام الكتب
              </p>
              <BiCategoryAlt />
              <span
                className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
              transition-all duration-200 flex items-center justify-center ${selectedMenu === "categories"
                    ? "w-full"
                    : "group-hover:w-full w-0 "
                  }`}
              ></span>
            </Link>
          </motion.div>
          <motion.div
            variants={vars}
            initial="initial"
            whileInView="whileInView"
            transition={{
              duration: 0.3,
              delay: 0.2,
            }}
          >
            <Link
              to="/authors"
              onClick={handleLinkClick}
              className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
              ease-in-out duration-200  ${selectedMenu === "authors"
                  ? "-translate-y-2 py-4"
                  : "hover:-translate-y-2 hover:py-4"
                }`}
            >
              <p dir="rtl" className="font-tajawal">
                المؤلفون
              </p>
              <TiGroupOutline />
              <span
                className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
              transition-all duration-200 flex items-center justify-center ${selectedMenu === "authors"
                    ? "w-full"
                    : "group-hover:w-full w-0 "
                  }`}
              ></span>
            </Link>
          </motion.div>
          {/* search */}
          <div>
            <SearchWeb handleToggleNav={handleToggleNav} />
          </div>
        </div>
        {/* mobile-view */}
        <div className="md:hidden">
          {/* menu Button */}

          <motion.button
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 17,
            }}
            ref={menuButtonRef}
            onClick={handleToggleNav}
            className={`md:hidden flex flex-col items-center justify-center space-y-1 z-50 ${toggleNav ? "fixed top-[1.9rem] right-[2rem]" : "sticky"
              }`}
            // z-index doesn't work on static positions
            animate={toggleNav ? "open" : "closed"}
          >
            <motion.span
              className={`h-[0.10rem] w-7 rounded-2xl bg-black relative ${toggleNav && "bg-black"
                }`}
              variants={{
                closed: { opacity: 1, rotate: "0deg" },
                open: { opacity: 1, rotate: "-45deg", y: 5 },
              }}
            >
            </motion.span>
            <motion.span
              className={`h-[0.10rem] w-7 rounded-2xl bg-black ${toggleNav && "bg-black"
                }`}
              variants={{
                closed: { opacity: 1, rotate: "0deg", y: 0 },
                open: { opacity: 1, rotate: "45deg", y: 0 },
              }}
            ></motion.span>
            <motion.span
              className={`h-[0.10rem] w-7 rounded-2xl bg-black ${toggleNav && "bg-black"
                }`}
              variants={{
                closed: { opacity: 1, rotate: "0deg" },
                open: { opacity: 0 },
              }}
            ></motion.span>
          </motion.button>
          {/* nav-mobile */}
          <AnimatePresence>
            {toggleNav && (
              <div className="z-50" ref={navRef} >
                <motion.div
                  variants={menuVars}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="fixed z-40 right-0 top-0 h-screen w-[18rem] bg-gradient-to-b from-yellow-500/70 
              flex items-center justify-center flex-col overflow-hidden md:hidden backdrop-blur-md 
              border-l-2 border-black"
                >
                  <div
                    className="flex flex-col items-center justify-start w-full h-full overflow-hidden
                    gap-9 relative px-2 pt-[7vh]"
                  >
                    <motion.div

                      transition={{
                        duration: 0.2,
                        type: "spring",
                        damping: 17,
                        stiffness: 400,
                      }}
                      className="flex items-center justify-center cursor-pointer"
                    >
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{
                          type: "spring",
                          damping: "17",
                          stiffness: "400",
                        }}
                        className="w-[6rem] h-[6rem] p-2 border-2 border-black rounded-full bg-yellow-200 
"
                      >
                        <img src={logo} alt="logo" className="w-full h-full object-contain" />
                      </motion.div>
                    </motion.div>
                    {/* menu items */}
                    {/*  */}
                    <div className="overflow-hidden">
                      <motion.div
                        variants={{
                          initial: { y: 60 },
                          animate: { y: 0 },
                          exit: { y: 60 },
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{}}
                        onClick={handleLinkClick}
                      >
                        <Link
                          onClick={() => setSelectedMenu("home")}
                          to="/"
                          className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
                          ease-in-out duration-200 text-xl  ${selectedMenu === "home"
                              ? "-translate-y-2 py-4"
                              : "hover:-translate-y-2 hover:py-4"
                            }`}
                        >
                          <p dir="rtl" className="font-tajawal">
                            الرئيسيه
                          </p>
                          <TiHomeOutline />
                          <span
                            className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
                          transition-all duration-200  flex items-center justify-center ${selectedMenu === "home" ? "w-full" : "group-hover:w-full w-0 "
                              }`}
                          ></span>
                        </Link>
                      </motion.div>
                    </div>
                    {/*  */}
                    <div className="overflow-hidden">
                      <motion.div
                        variants={{
                          initial: { y: 60 },
                          animate: { y: 0 },
                          exit: { y: 60 },
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{
                          delay: 0.1,
                          ease: "easeInOut",
                        }}
                        onClick={handleLinkClick}
                      >
                        <Link
                          to="/categories"
                          className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
                          ease-in-out duration-200 text-xl  ${selectedMenu === "categories"
                              ? "-translate-y-2 py-4"
                              : "hover:-translate-y-2 hover:py-4"
                            }`}
                        >
                          <p dir="rtl" className="font-tajawal">
                            اقسام الكتب
                          </p>
                          <BiCategoryAlt />
                          <span
                            className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
                          transition-all duration-200 flex items-center justify-center ${selectedMenu === "categories"
                                ? "w-full"
                                : "group-hover:w-full w-0 "
                              }`}
                          ></span>
                        </Link>
                      </motion.div>
                    </div>
                    {/*  */}
                    <div className="overflow-hidden">
                      <motion.div
                        variants={{
                          initial: { y: 60 },
                          animate: { y: 0 },
                          exit: { y: 60 },
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{
                          delay: 0.2,
                        }}
                      >
                        <Link
                          to="/authors"
                          onClick={handleLinkClick}
                          className={`flex items-center justify-center gap-1 group relative py-2 px-2 transition-all 
                          ease-in-out duration-200 text-xl  ${selectedMenu === "authors"
                              ? "-translate-y-2 py-4"
                              : "hover:-translate-y-2 hover:py-4"
                            }`}
                        >
                          <p dir="rtl" className="font-tajawal">
                            المؤلفون
                          </p>
                          <TiGroupOutline />
                          <span
                            className={`ease-in-out absolute bottom-0 right-0 h-0 border-b-2 border-yellow-600 
                          transition-all duration-200 flex items-center justify-center ${selectedMenu === "authors" ? "w-full" : "group-hover:w-full w-0"
                              }`}
                          ></span>
                        </Link>
                      </motion.div>
                    </div>
                    {/*  */}
                    {/* search */}

                    <div className=" flex items-center justify-center h-[4rem]">
                      <SearchMobile handleToggleNav={handleToggleNav} />
                    </div>
                  </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
