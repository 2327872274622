import React, { createContext, useState, useContext, useEffect } from "react";
import { DataContext } from "./DataContext";

const CartContext = createContext(null);

const CartContextProvider = ({ children }) => {
    const { products } = useContext(DataContext);
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = JSON.parse(localStorage.getItem("cartItems"));
        return savedCart || {};
    });

    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (productId) => {
        setCartItems((prevItems) => {
            const newCart = { ...prevItems, [productId]: (prevItems[productId] || 0) + 1 };
            return newCart;
        });
    };

    const removeFromCart = (productId) => {
        setCartItems((prevItems) => {
            const newCart = { ...prevItems };
            delete newCart[productId];
            return newCart;
        });
    };

    const increaseItemQuantity = (productId) => {
        setCartItems((prevItems) => {
            const newCart = { ...prevItems, [productId]: (prevItems[productId] || 0) + 1 };
            return newCart;
        });
    };

    const decreaseItemQuantity = (productId) => {
        setCartItems((prevItems) => {
            const newCart = { ...prevItems };
            if (newCart[productId] > 1) {
                newCart[productId] -= 1;
            } else {
                delete newCart[productId];
            }
            return newCart;
        });
    };

    const getTotalPrice = () => {
        return Object.keys(cartItems).reduce((total, productId) => {
            const product = products.find((p) => p.productId === productId);
            if (!product) {
                // console.error(`Product with ID ${productId} not found`);
                return total;
            }
            return total + product.productPrice * cartItems[productId];
        }, 0);
    };

    const getTotalCartItems = () => {
        return Object.values(cartItems).reduce((total, numItems) => total + numItems, 0);
    };

    const getItemQuantity = (productId) => {
        return cartItems[productId] || 0;
    };

    const getItemTotalPrice = (productId) => {
        const product = products.find((p) => p.productId === productId);
        if(!product) {
            console.error(`Cannot Find a Product with the  ID ${productId}`)
            return 0;
        }
        return product.productPrice * (cartItems[productId] || 0);
    }

    const clearCart = () => {
        setCartItems({});
        localStorage.removeItem("cartItems");
    };

    const refreshCart = () => {
        const savedCart = JSON.parse(localStorage.getItem("cartItems"));
        if (savedCart) {
            setCartItems(savedCart);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(refreshCart, 1000); // Adjust the interval as needed
        return () => clearInterval(intervalId);
    }, []);

    const contextValue = {
        cartItems,
        addToCart,
        removeFromCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        getTotalPrice,
        getTotalCartItems,
        getItemQuantity,
        getItemTotalPrice,
        clearCart,
        refreshCart
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

const useCart = () => {
    return useContext(CartContext);
};

export { CartContextProvider, useCart };






  // const getDefaultCart = () => {
    //     let cart = {};
    //     products.forEach(product => {
    //         cart[product.productId] = 0;
    //     });
    //     return cart;
    // };

    // useEffect(() => {
    //     if (!loading && products.length > 0) {
    //         setCartItems(getDefaultCart());
    //     }
       
    // }, [products, loading]);