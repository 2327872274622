import { useState, useEffect, createContext } from "react";
import { db } from "../../Firebase/config";
import { collection, onSnapshot } from "firebase/firestore";

export const DataContext = createContext(null);

const DataContextProvider = (props) => {
    const [products, setProducts] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(true);
    const [authorsLoading, setAuthorsLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [locationsLoading, setLocationsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "products"),
            (snapshot) => {
                const updatedProducts = [];
                snapshot.forEach((doc) => {
                    updatedProducts.push(doc.data());
                });
                setProducts(updatedProducts);
                setLoading(false);
            },
            (error) => {
                console.error("Error fetching products:", error);
                setErr(true);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "authors"),
            (snapshot) => {
                const updatedAuthors = [];
                snapshot.forEach((doc) => {
                    updatedAuthors.push(doc.data());
                });
                setAuthors(updatedAuthors);
                setAuthorsLoading(false);
            },
            (error) => {
                console.error("Error fetching Authors:", error);
                setErr(true);
                setAuthorsLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "locations"),
            (snapshot) => {
                const updatedLocations = [];
                snapshot.forEach((doc) => {
                    updatedLocations.push(doc.data());
                });
                setLocations(updatedLocations);
                setLocationsLoading(false);
            },
            (error) => {
                console.error("Error fetching locations:", error);
                setErr(true);
                setLocationsLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    const contextValue = { products, loading, err, authors, authorsLoading, setLoading, setAuthorsLoading, locations, locationsLoading, setLocationsLoading };
    return (
        <DataContext.Provider value={contextValue}>
            {props.children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;
