import { motion } from "framer-motion";

const Loading = () => {

  const ContainerVariants = {
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const DotVariants = {
    animate: {
      y: ["0%", "100%"],
    },
  };

  const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  return (
    <div className='flex items-center justify-center h-screen'>
      <div
        style={{
          paddingTop: "5rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <motion.div className="w-[10rem] h-[5rem] flex justify-around"
          variants={ContainerVariants}
          initial="initial"
          animate="animate"
        >
          <motion.span
            className="block w-8 h-8 bg-black rounded-[50%]"
            variants={DotVariants}
            transition={DotTransition}
          />
          <motion.span
            className="block w-8 h-8 bg-black rounded-[50%]"
            variants={DotVariants}
            transition={DotTransition}
          />
          <motion.span
            className="block w-8 h-8 bg-black rounded-[50%]"
            variants={DotVariants}
            transition={DotTransition}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Loading;
