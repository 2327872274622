import { LuSearch } from "react-icons/lu";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { db } from "../../Firebase/config";
import {
  query,
  collection,
  where,
  getDocs,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import LoadingMini from "../Suspense/LoadingMini";
import { RxCross2 } from "react-icons/rx";
import LoadingDots from "../Suspense/LoadingDots";
import { BsCartPlusFill } from "react-icons/bs";
import { useCart } from "../Context/CartContext";
import { FaTrash } from "react-icons/fa";
import { TbShoppingCartCheck } from "react-icons/tb";
import { Link } from "react-router-dom";
import { GiShoppingCart } from "react-icons/gi";
import ScrollUp from "../ScrollUp/ScrollUp";

const Search = ({ handleToggleNav }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [cartMessage, setCartMessage] = useState("");
  const [cartProductId, setCartProductId] = useState(null);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);
  const clearRef = useRef(null);
  const { addToCart, removeFromCart, getItemQuantity, getTotalCartItems } = useCart();
  const totalCartItems = getTotalCartItems();

  useEffect(() => {
    if (cartMessage !== "") {
      const timer = setTimeout(() => {
        setCartMessage("");
        setCartProductId(null);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [cartMessage]);

  const handleCartClick = (productId) => {
    if (getItemQuantity(productId) > 0) {
      removeFromCart(productId);
      setCartMessage("تم حذف المنتج");
    } else {
      addToCart(productId);
      setCartMessage("تمت اضافه المنتج");
    }
    setCartProductId(productId);
  };

  async function fetchData() {
    try {
      setLoading(true);
      if (searchTerm !== "") {
        const q = query(
          collection(db, "products"),
          orderBy("productName"),
          where("productName", ">=", searchTerm),
          where("productName", "<=", searchTerm + "\uf8ff")
        );

        const q2 = query(
          collection(db, "products"),
          orderBy("authorName"),
          where("authorName", ">=", searchTerm),
          where("authorName", "<=", searchTerm + "\uf8ff")
        );

        const [snapShot1, snapShot2] = await Promise.all([
          getDocs(q),
          getDocs(q2),
        ]);

        const docs = [];
        snapShot1.forEach((doc) => {
          docs.push(doc.data());
        });
        snapShot2.forEach((doc) => {
          docs.push(doc.data());
        });

        const filteredResults = docs.filter((product) => {
          return (
            product.productName.startsWith(searchTerm.slice(0, 2)) ||
            product.authorName.startsWith(searchTerm.slice(0, 2))
          );
        });

        setSearchResults(filteredResults);
      } else {
        setSearchResults([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  }

  function handleInputChange(event) {
    const value = event.target.value;
    setSearchTerm(value);
  }

  function getTextDirection(text) {
    const arabicRegex = /[\u0600-\u06FF]/;

    if (!arabicRegex.test(text)) {
      return "ltr";
    }
    return "rtl";
  }

  function highlightMatches(text, searchTerm) {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const segments = text.split(regex);
    let mergedSegments = [];

    for (let i = 0; i < segments.length; i++) {
      if (segments[i].toLowerCase() === searchTerm.toLowerCase()) {
        // Merge with the previous segment
        if (
          i > 0 &&
          typeof mergedSegments[mergedSegments.length - 1] === "string"
        ) {
          mergedSegments[mergedSegments.length - 1] += segments[i];
        } else {
          mergedSegments.push(segments[i]);
        }
      } else {
        mergedSegments.push(segments[i]);
      }
    }

    return mergedSegments.map((segment, index) => {
      if (segment.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <span key={index} className="font-bold bg-gray-300">
            {segment}
          </span>
        );
      } else {
        return segment;
      }
    });
  }

  useEffect(() => {
    fetchData();
    const unsubscribe = onSnapshot(collection(db, "products"), () => {
      fetchData();
    });
    return unsubscribe;
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        resultsRef.current &&
        !resultsRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        clearRef.current &&
        !clearRef.current.contains(event.target)
      ) {
        setIsResultsVisible(false);
      } else if (inputRef.current && inputRef.current.contains(event.target)) {
        setIsResultsVisible(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setIsResultsVisible(true);
    } else {
      setIsResultsVisible(false);
    }
  }, [searchTerm]);

  const searchVars = {
    initial: {
      width: 0,
      opacity: 0,
    },
    animate: {
      width: "100%",
      opacity: 1,
    },
    whileInView: {
      width: "100%",
      opacity: 1,
    },
  };

  const inputVariants = {
    initial: {
      transformOrigin: "right center",
    },
    whileInView: {
      transformOrigin: "right center",
    },
  };

  const encodeId = (id) => {
    return btoa(id);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsResultsVisible(false);
    handleToggleNav();
  };

  const sliceWords = (text) => {
    let words = text;
    if (text.length > 35) {
      words = `${text.slice(0, 34)}...`;
    } else {
      words = text;
    }
    return words;
  };
  return (
    <div dir="rtl" className="flex flex-col items-center justify-center relative">
      <motion.div
        variants={searchVars}
        initial="initial"
        whileInView="whileInView"
        transition={{
          duration: 0.9,
          delay: 0.4,
        }}
        dir="rtl"
        style={{ transformOrigin: "right center" }}
        className="flex items-center justify-center w-full overflow-hidden rounded-full relative 
        border-black border-2"
      >
        <ScrollUp />
        <motion.input
          type="text"
          value={searchTerm}
          ref={inputRef}
          dir={getTextDirection(searchTerm)}
          onChange={handleInputChange}
          variants={inputVariants}
          placeholder="ابحث باسم الكتاب او اسم المؤلف"
          className={`flex items-center justify-center pr-7 pl-[3rem] py-2 rounded-full  w-[22rem] 
           focus:outline-none placeholder:text-right focus:placeholder-transparent`}
        />
        <div
          className={`absolute left-0 flex items-center justify-center rounded-l-full w-[2.7rem] h-[2.7rem] 
            bg-yellow-500 border-black
            ${searchTerm === "" ? "text-gray-500 " : "text-white"}
            `}
          dir="rtl"
        >
          <motion.button
            whileHover={{ scale: searchTerm === "" ? 1 : 1.2 }}
            whileTap={{ scale: 0.9 }}
            disabled={searchTerm === ""}
            onClick={() => fetchData()}
            className="flex w-full h-full items-center justify-center"
          >
            <LuSearch />
          </motion.button>
        </div>
      </motion.div>
      <AnimatePresence>
        {isResultsVisible && searchTerm && (
          <div className="absolute top-full left-0 right-0 flex flex-col items-center justify-center 
          w-full mt-2 z-20">
            <motion.div
              dir="rtl"
              ref={resultsRef}
              className="flex items-center justify-start flex-col border-2 border-black rounded-lg 
              w-[20rem] overflow-hidden  max-h-[20rem] overflow-y-scroll 
              shadow-lg shadow-black bg-white"
            >
              {loading ? (
                <div className="w-full flex items-center justify-center py-3">
                  <LoadingMini />
                </div>
              ) : searchResults.length > 0 ? (
                searchResults.map((product, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center gap-2 py-2"
                  >
                    <Link to={`/categories/${product.categories[0]}/${encodeId(product.productId)}`}
                      onClick={handleLinkClick}
                    >
                      <motion.div
                        dir="rtl"
                        className="flex items-start justify-start gap-2 border-2 rounded-lg pl-2 shadow-lg 
                      shadow-black border-black overflow-hidden relative hover:shadow-yellow-500"
                      >
                        <div
                          className="flex items-center justify-center w-[5rem] h-[7rem]
                        overflow-hidden border-l-2 border-black"
                        >
                          {loading ? (
                            <LoadingDots />
                          ) : (
                            <img
                              src={product.productImg}
                              alt={product.productName}
                              className="w-full h-full object-cover"
                            />
                          )}
                        </div>
                        <div
                          className="flex flex-col items-start justify-center h-[7rem] 
                      overflow-y-scroll gap-2 no-scrollbar relative"
                        >
                          <div
                            dir={getTextDirection(product.productName)}
                            className="w-[9rem] break-words text-center font-bold relative group"
                          >
                            {highlightMatches(sliceWords(product.productName), searchTerm)}
                            <div className="absolute w-[9rem]  transform  -translate-y-[3.1rem]
                  mb-2 hidden group-hover:block bg-black text-white text-sm rounded py-1 
                  px-2 break-words z-10">
                              {product.productName}
                            </div>
                          </div>
                          <div
                            dir={getTextDirection(product.authorName)}
                            className="w-[9rem] break-words py-1 text-center text-sm"
                          >
                            {highlightMatches(
                              product.authorName.replace(/-/g, " "),
                              searchTerm
                            )}
                          </div>
                          <AnimatePresence>
                            {getItemQuantity(product.productId) > 0 ? (
                              <motion.div
                                variants={{
                                  initial: { scale: 0 },
                                  animate: { scale: 1 },
                                  exit: { scale: 0 },
                                }}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="absolute bottom-2 left-0"
                              >
                                <TbShoppingCartCheck />
                              </motion.div>
                            ) : null}
                          </AnimatePresence>
                        </div>

                        {/* cart message */}
                        <AnimatePresence>
                          {cartMessage &&
                            cartProductId === product.productId && (
                              <motion.div
                                key="cartMessage"
                                initial={{ opacity: 0, y: 150 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 150 }}
                                className="bg-yellow-500 text-black py-2 px-4 rounded text-sm
                              z-0 absolute bottom-0 translate-x-1/2"
                              >
                                {cartMessage}
                              </motion.div>
                            )}
                        </AnimatePresence>
                      </motion.div>
                    </Link>
                    <LayoutGroup>
                      <motion.div layout
                        className="flex flex-col items-center justify-start gap-3">
                        {/* add to cart */}
                        <motion.div
                          layout
                          dir="rtl"
                          className="flex items-center justify-center"
                        >
                          <div
                            className="group relative flex items-center justify-center gap-2"
                            dir="rtl"
                          >
                            <motion.button
                              onClick={() => handleCartClick(product.productId)}
                              dir="rtl"
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              className="flex items-center justify-center rounded-lg border-2 border-black
                           w-[2.7rem] h-[2.7rem] z-10 bg-yellow-500 text-black hover:text-white shadow-md shadow-black"
                            >
                              {getItemQuantity(product.productId) > 0 ? (
                                <div
                                  dir="rtl"
                                  className="flex items-center justify-center gap-2 "
                                >
                                  <FaTrash size={20} />
                                </div>
                              ) : (
                                <div
                                  dir="rtl"
                                  className="flex items-center justify-center gap-2"
                                >
                                  <BsCartPlusFill size={25} />
                                </div>
                              )}
                            </motion.button>
                            <div
                              dir="rtl"
                              className=" group-hover:block w-3 h-3 left-[35%] transform -translate-y-[2.1rem]
                             rotate-45 bg-black absolute
                            hidden z-20 "
                            ></div>
                            <span
                              className="absolute left-[20%] transform -translate-y-[3px] bottom-full 
                            mb-2 hidden group-hover:block bg-black text-white text-sm rounded py-1 
                            px-2 whitespace-nowrap "
                            >
                              {getItemQuantity(product.productId) > 0
                                ? "احذف من العربه"
                                : "اضف للعربه"}
                            </span>
                          </div>
                        </motion.div>
                        {/* cart message */}
                        <AnimatePresence>
                          {getItemQuantity(product.productId) > 0 ? (
                            <motion.div
                              key="goToCart"
                              variants={{
                                initial: { scale: 0 },
                                animate: { scale: 1 },
                                exit: { scale: 0 },
                              }}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              className="group relative flex items-center justify-center gap-2"
                            >
                              <Link to="/cart">
                                <motion.button
                                  dir="rtl"
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                  className="flex items-center justify-center rounded-lg border-2 border-black
                                  w-[2.7rem] h-[2.7rem] z-10 bg-yellow-500 text-black hover:text-white shadow-md shadow-black"
                                >
                                  <GiShoppingCart size={25} />
                                  {totalCartItems > 0 && (
                                    <div className="absolute top-0 right-0 -translate-y-1 z-10 translate-x-1">
                                      <div
                                        className="w-3 h-3 relative rounded-full bg-blue-500 transition-all animate-ping 
                                        duration-100 ease-in-out flex items-center justify-center"
                                      ></div>
                                      <div className="w-3 h-3 rounded-full bg-blue-500 absolute top-0"></div>
                                    </div>
                                  )}
                                </motion.button>
                              </Link>
                              <div
                                dir="rtl"
                                className=" group-hover:block w-3 h-3 left-[35%] transform -translate-y-[2.1rem]
                             rotate-45 bg-black absolute
                            hidden z-20 "
                              ></div>
                              <span
                                className="absolute left-[20%] transform -translate-y-[3px] bottom-full 
                            mb-2 hidden group-hover:block bg-black text-white text-sm rounded py-1 
                            px-2 whitespace-nowrap z-10"
                              >
                                الذهاب للعربه
                              </span>
                            </motion.div>
                          ) : null}
                        </AnimatePresence>
                      </motion.div>
                    </LayoutGroup>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center w-full py-3">
                  <p>لا توجد نتائج</p>
                </div>
              )}
            </motion.div>
            {searchTerm !== "" ? (
              <div ref={clearRef} className="flex items-center justify-center p-4">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setSearchTerm("")}
                  className="flex items-center justify-center w-8 h-8 rounded-full border-black border-2
              shadow-lg shadow-black bg-white
          "
                >
                  <RxCross2 />
                </motion.button>
              </div>
            ) : null}
          </div>

        )}
      </AnimatePresence>
    </div>
  );
};

export default Search;
