import { Suspense, lazy, useContext } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Header/Navbar";
import Footer from "./components/Footer/Footer";
import Loading from "./components/Suspense/Loading";
import { AuthContext } from "./components/Context/AuthContext";
import ScrollToTop from "./components/ScrollUp/ScrollToTop";

const Home = lazy(() => import("./components/Pages/Home"));
const Category = lazy(() => import("./components/Pages/Category"));
const Product = lazy(() => import("./components/Pages/Product"));
const Cart = lazy(() => import("./components/Pages/Cart"));
const Authors = lazy(() => import("./components/Pages/Authors"));
const Login = lazy(() => import("./components/Admin/Login"));
const AddProduct = lazy(() => import("./components/Admin/AddProduct"));
const EditProducts = lazy(() => import("./components/Admin/EditProducts"));
const Categories = lazy(() => import("./components/Pages/Categories"));
const AddAuthors = lazy(() => import("./components/Admin/AddAuthors"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const EditAuthors = lazy(() => import("./components/Admin/EditAuthors"));
const AddEditLocations = lazy(() => import("./components/Admin/AddEditLocations"));
const Author = lazy(() => import("./components/Pages/Author"));
const AboutUs = lazy(() => import("./components/Pages/AboutUs"));
const ContactUs = lazy(() => import("./components/Pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("./components/Pages/PrivacyPolicy"));
const Founder = lazy(() => import("./components/Pages/Founder"));
function App() {

  const ProtectedRoute = ({ children }) => {
    let Location = useLocation();
    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
      return (<Navigate to="/adminLoginRoute" state={{ from: Location }} replace />);
    }
    return children;
  };


  return (
    <div className="flex items-center justify-center flex-col">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/adminLoginRoute" element={<Login />} />
            <Route path="/admincontrolpanelroute" index element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            } />
            <Route path="/addProductAdminRoute" index element={
              <ProtectedRoute>
                <AddProduct />
              </ProtectedRoute>
            } />
            <Route path="/editproductsadminroute" index element={
              <ProtectedRoute>
                <EditProducts />
              </ProtectedRoute>
            } />
            <Route path="/addauthoradminroute" index element={
              <ProtectedRoute>
                <AddAuthors />
              </ProtectedRoute>
            } />
            <Route path="/editauthoradminroute" index element={
              <ProtectedRoute>
                <EditAuthors />
              </ProtectedRoute>
            } />
            <Route path="/addeditlocationsadminroute" index element={
              <ProtectedRoute>
                <AddEditLocations />
              </ProtectedRoute>
            } />
            <Route path="/categories/:category" element={<Category />} ></Route>
            <Route path="/categories/:category/:productId" element={<Product />} />
            <Route path="/product" element={<Product />}>
            </Route>
            <Route path="/cart" element={<Cart />} />
            <Route path="/authors" element={<Authors />} ></Route>
            <Route path="/authors/:authorId" element={<Author />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/founder" element={<Founder />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
