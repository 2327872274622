import React from "react";
import logo from "../Assets/images/logo mini.png";
import { motion } from "framer-motion";
import {
  FaSpotify,
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="flex flex-col items-center justify-center font-tajawal w-screen 
    px-8 py-4 bg-center bg-no-repeat object-cover space-y-5 bg-yellow-600 border-t-2 border-black"
    >
      {/* left-side-logo */}
      <motion.div
        transition={{
          duration: 0.2,
          type: "spring",
          damping: 17,
          stiffness: 400,
        }}
        className="flex items-center justify-center cursor-pointer"
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{
            type: "spring",
            damping: "17",
            stiffness: "400",
          }}
          className="w-[6rem] h-[6rem] p-2 border-2 border-black rounded-full bg-yellow-500 
        "
        >
          <img src={logo} alt="logo" className="w-full h-full object-contain" />
        </motion.div>
      </motion.div>
      {/* middle-side */}
      <div className="flex flex-col items-center text-center gap-2">
        <motion.div>
          <p className="text-center font-bold text-lg" dir="rtl">
            هَلُم إلى سطور تنبض بالحياة          </p>
        </motion.div>
        {/* links */}
        <div
          className="flex flex-col md:flex-row items-center justify-center gap-3 text-lg"
          dir="rtl"
        >
          <Link to="/aboutUs">
            <motion.div>
              <p className="border-b-2 border-blue-700 text-black hover:text-gray-100">
                عن صيد الخاطر
              </p>
            </motion.div>
          </Link>

          <Link to="/founder">
            <motion.div
            >
              <p className="border-b-2 border-blue-700 text-black hover:text-gray-100">عن المؤسس</p>
            </motion.div>
          </Link>

          <Link to="/privacyPolicy">
            <motion.div>
              <p className="border-b-2 border-blue-700 text-black hover:text-gray-100">
                سياسة الخصوصيه
              </p>
            </motion.div>
          </Link>
          <Link to="/contactUs">
            <motion.div>
              <p className="border-b-2 border-blue-700 text-black hover:text-gray-100">
                تواصل معنا
              </p>
            </motion.div>
          </Link>
        </div>
      </div>
      {/* right-side-social-copyrights */}
      <div className="flex flex-col items-center justify-center lg:justify-end gap-2">
        {/* social-icons */}
        <motion.div
          transition={{
            duration: 0.2,
            delay: 0.6,
            type: "spring",
            damping: 17,
            stiffness: 400,
          }}
          className="flex items-center justify-center gap-3 text-lg"
        >
          {/* spotify */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              damping: 17,
              stiffness: 400,
            }}
          >
            <a
              href="https://podcasters.spotify.com/pod/show/saidalkaterpublishing"
              rel="noreferrer"
              target="_blank"
            >
              <FaSpotify />
            </a>
          </motion.div>
          {/* facebook */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              damping: 17,
              stiffness: 400,
            }}
          >
            <a
              href="https://www.facebook.com/saidalkaterpublishing/?locale=nl_NL"
              rel="noreferrer"
              target="_blank"
            >
              <FaFacebook />
            </a>
          </motion.div>
          {/* youtube */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              damping: 17,
              stiffness: 400,
            }}
          >
            <a
              href="https://www.youtube.com/@saidalkaterpublishing/addons/unicode-categories"
              rel="noreferrer"
              target="_blank"
            >
              <FaYoutube />
            </a>
          </motion.div>
          {/* instagram */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              damping: 17,
              stiffness: 400,
            }}
          >
            <a
              href="https://www.instagram.com/saidalkaterpublishing/?igsh=MXdsNWM5eG82eGc4cA%3D%3D"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </motion.div>
          {/* tiktok */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{
              type: "spring",
              damping: 17,
              stiffness: 400,
            }}
          >
            <a
              href="https://www.tiktok.com/@saidalkaterpublishing"
              rel="noreferrer"
              target="_blank"
            >
              <FaTiktok />
            </a>
          </motion.div>
        </motion.div>
        <motion.div
          transition={{
            duration: 0.4,
            delay: 0.7,
            type: "spring",
            damping: 17,
            stiffness: 400,
          }}
        >
          <h2
            className="border-b-2 text-gray-100 text-center text-md"
            dir="rtl"
          >
            جميع حقوق النشر محفوظه {currentYear} &copy; صيد الخاطر للنشر
          </h2>
        </motion.div>
      </div>
    </div>
  );
};

export default Footer;
