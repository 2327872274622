import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './components/Context/AuthContext';
import DataContextProvider from './components/Context/DataContext';
import { CartContextProvider } from './components/Context/CartContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <DataContextProvider>
      <CartContextProvider>
          <App />
      </CartContextProvider>
    </DataContextProvider>
  </AuthContextProvider>
);
