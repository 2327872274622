import React from "react";
import { RiArrowUpDoubleFill } from "react-icons/ri";

const ScrollUp = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="fixed bottom-[1rem] right-[1rem] flex items-center justify-center z-20">
      <button className="rounded-full w-[4rem] h-[4rem] bg-black text-white flex items-center justify-center" onClick={handleClick}>
        <RiArrowUpDoubleFill size={25}/>
      </button>
    </div>
  );
};

export default ScrollUp;
