import { motion } from "framer-motion";
import { GiShoppingCart } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useCart } from "../Context/CartContext";

const CartNavigation = () => {
  const { getTotalCartItems } = useCart();

  const totalCartItems = getTotalCartItems();
  return (
    <div>
      <Link to="/cart" className="relative">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{
            type: "spring",
            damping: 17,
            stiffness: 400,
          }}
          className="flex items-center justify-center rounded-lg border-2 
            border-black w-11 h-11 bg-yellow-400"
        >
          <GiShoppingCart size={30} className="" />
        </motion.div>
        {totalCartItems > 0 && (
          <div className="absolute top-0 right-0 -translate-y-1 z-10 translate-x-1">
            <div
              className="w-3 h-3 relative rounded-full bg-blue-500 transition-all animate-ping 
            duration-100 ease-in-out flex items-center justify-center"
            ></div>
            <div className="w-3 h-3 rounded-full bg-blue-500 absolute top-0"></div>
          </div>
        )}
      </Link>
    </div>
  );
};

export default CartNavigation;
